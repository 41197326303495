$form-height: 48px;

$form-border-radius: 2px !default;
$btn-border-radius: 4px !default;
$modal-radius: 0 !default;
$label-radius: 0 !default;
$box-radius: 8px !default;


// Spacing
$block-spacing: 1.5rem !default;

$spacer: 12px;
$grid-gutter-width: 24px;

// Responsiveness
$gap: 24px !default; //$called spacer on bootstrap;
$gap-half: calc($gap/2) !default;
$gap-quater: calc($gap/4) !default;
$gap-2: $gap*2 !default;
$gap-3: $gap*3 !default;
$gap-4: $gap*4 !default;


$tablet: 768px !default;
$desktop: 992 !default;
$widescreen: 1200 !default;
$fullhd: 1400 !default;

$container-gap: $gap;


$header-height: 96px !default;
$sub-nav-height: 40px;
$header-primary-height: 60px !default;
$header-secondary-height: 31px !default;
$header-height-mobile: calc($header-height + 16px) !default;
$sub-nav-height-mobile: calc($sub-nav-height + 16px) !default;
$mobile-footer-nav-height: 60px;
$global-message-height: 40px;
$cart-preview-width: 356px !default;

$zindex-levels: (
    n1: -1,
    0: 0,
    1: 1,
    2: 2,
    3: 3,
    1020: 1020
);