.text-secondary-700 {
  color: $secondary-700;
}

.text-secondary-800 {
  color: $secondary-800;
}

.text-gray {
  color: $gray-color;
}

.font-variant-nums {
  font-variant-numeric: tabular-nums;
}

.font-size-6 {
  font-size: $size-6 !important;
}

.link-color {
  color: $secondary-color;

  &:hover {
    color: $primary-500;
  }
}